import React from "react";
import githubIcon from "./assets/contact/githubIcon.png";
import linkedInIcon from "./assets/contact/linkedInIcon.png";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="contact-title">Contact me!</div>
      <div className="contact-email">adrian@sujkovic.com</div>
      <div className="contact-content-wrapper">
        <div className="contact-icons">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/sujkovic/"
          >
            <img src={githubIcon} alt="github" className="github-icon"></img>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/adrian-sujkovic/"
          >
            <img
              src={linkedInIcon}
              alt="github"
              className="linked-in-icon"
            ></img>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
