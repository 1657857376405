import React, { useRef, useEffect } from "react";

const Home = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const layers =
        containerRef.current.getElementsByClassName("parallax-layer");

      for (let i = 0; i < layers.length; i++) {
        const speed = parseFloat(layers[i].getAttribute("data-speed"));
        if (layers[i].classList.contains("ferry")) {
          console.log("AHHHHH");
          const xPos = -scrollY * speed * (i + 1); // Update this line
          layers[i].style.transform = `translateX(${xPos}px)`;
        } else {
          const yPos = scrollY * speed * (i + 1); // Update this line
          layers[i].style.transform = `translateY(${yPos}px)`;
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="home" className="home" ref={containerRef}>
      <div className="home-container parallax-layer" data-speed=".14">
        <div className="home-title">Adrian Sujkovic</div>
        <div className="home-subtitle">Software Developer</div>
      </div>
      <div
        className="parallax-layer around-world-trade-closer"
        data-speed="0.08"
      ></div>
      <div
        className="parallax-layer around-world-trade-farther"
        data-speed="0.06"
      ></div>
      <div
        className="parallax-layer world-trade-center"
        data-speed="0.11"
      ></div>
      <div className="parallax-layer ferry" data-speed="0.08"></div>
      <div className="parallax-layer left-side-behind" data-speed="0.03"></div>
      <div
        className="parallax-layer left-side-overlaps"
        data-speed="0.02"
      ></div>
      <div className="parallax-layer right-side" data-speed="0.02"></div>
      <div className="background-bottom"></div>
      <div className="blur-overlay"></div>
    </section>
  );
};

export default Home;
