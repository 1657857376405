import { useEffect } from "react";
import "./index.css";
import Header from "./Header";
import Home from "./Home";
import Me from "./Me";
import Works from "./Works";
import Projects from "./Projects";
import Contact from "./Contact";
import Footer from "./Footer";

function App() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App">
      <Header />
      <Home />
      <Me />
      <Works />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
