import React from "react";

const Header = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <header>
      <div onClick={() => scrollToSection("home")} className="headerItem">
        Home
      </div>
      <div onClick={() => scrollToSection("me")} className="headerItem">
        Me
      </div>
      <div onClick={() => scrollToSection("works")} className="headerItem">
        Work
      </div>
      <div onClick={() => scrollToSection("projects")} className="headerItem">
        Projects
      </div>
      <div onClick={() => scrollToSection("contact")} className="headerItem">
        Contact
      </div>
      {/* <div className="headerItem">Dark Mode</div> */}
    </header>
  );
};

export default Header;
