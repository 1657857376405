import React, { useEffect, useRef, useState } from "react";

const Project = ({ project }) => {
  const projectRef = useRef(null);
  const parallaxLayerRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, options);

    let curRef = projectRef.current;
    if (curRef) {
      observer.observe(curRef);
    }

    return () => {
      if (curRef) {
        observer.unobserve(curRef);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isInView && parallaxLayerRef.current) {
        const scrollY = window.scrollY;
        const speed = window.innerWidth > 900 ? 0.12 : 0.03; // Adjust this value to control parallax speed
        const yPos = -scrollY * speed;
        parallaxLayerRef.current.style.transform = `translateY(${yPos}px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isInView]);
  return (
    <div className="project" ref={projectRef} id={project.id}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={project.link}
        className="project-video-wrapper"
      >
        {project.demoIsVideo ? (
          <video className="project-video" autoPlay muted loop>
            <source
              src={project.videoUrl}
              alt={`${project.videoUrl}`}
              type="video/mp4"
            ></source>
          </video>
        ) : (
          <img
            src={project.videoUrl}
            alt={`${project.videoUrl}`}
            className="project-video"
          ></img>
        )}
      </a>
      <div
        className={`project-main project${project.id}`}
        data-speed="0.1"
        key={project.id}
        ref={parallaxLayerRef}
      >
        <div className="project-title">{project.title}</div>
        <div className="project-description">{project.description}</div>
        <div className="project-tech-stack">
          {project.techStack.map((icon) => (
            <img
              id={icon.iconId}
              title={icon.iconTitle}
              src={`${process.env.PUBLIC_URL}${icon.iconUrl}`}
              alt={icon.iconTitle}
              className="project-icon"
            ></img>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Project;
