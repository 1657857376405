import React from "react";

const Footer = () => {
  return (
    <footer>
      <div>&copy; Adrian Sujkovic 2023</div>
    </footer>
  );
};

export default Footer;
