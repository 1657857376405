import oti from "./assets/videos/oti.jpg";
import onePolicy from "./assets/videos/onePolicy.png";

const WorkList = [
  {
    id: 1,
    title: "Software Engineer Intern",
    company: "Cyber Command (NYC OTI)",
    location: "New York, NY",
    date: "June '23 - August '23",
    link: "https://www.nyc.gov/content/oti/pages/meet-the-team/cyber-command",
    description:
      "Got the opportunity to work in two teams over the summer in the DMARC and Server Coverage projects (outlined below). We worked using an AGILE sprint methodology aided by Jira and weekly sprint planning. In addition to these two projects, I also got to help implement a CI/CD workflow with Terraform and GCP build triggers to deploy IaaS, PaaS, and SaaS services.",
    image: oti,
  },
  {
    id: 2,
    title: "Software Engineer",
    company: "One Policy",
    location: "Binghamton, NY",
    date: "January '23 - May '23",
    link: "https://www.policys.me/",
    description:
      "My friends were talking about a startup they just made while I was in my automata theory class. I asked to join, and got the opportunity to create routes for our Node.js REST API backend to perform CRUD operations, using postman to test them. In addition to working on the backend, I also helped teach the younger students React and advertised the club we made at Binghamton University to teach web devlopment as part of our nonprofit. We ended up disbanding the business due to a lack of coordination that was not sufficient to compete with multibillion dollar businesses, but the experience was great and I got better at learning new technologies in very short time frames.",
    image: onePolicy,
  },
];

export default WorkList;
