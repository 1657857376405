import React, { useEffect } from "react";
import Work from "./Work";
import WorksList from "./WorksList";

const Works = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));
  }, []);
  return (
    <section id="works" className="works">
      <div className="title">Work Experience</div>
      <div className="works-wrapper">
        {WorksList.map((work) => (
          <Work id={work.id} work={work} />
        ))}
      </div>
    </section>
  );
};

export default Works;
