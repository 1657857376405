import React from "react";
import ProfilePic from "./assets/profile-pic.jpg";

const Me = () => {
  return (
    <section id="me" className="me">
      <div className="title">Me</div>
      <div className="me-main">
        <img src={ProfilePic} alt="Me" className="img-me"></img>
        <div className="me-intro">
          Hey, I'm Adrian, I'm currently a senior at Binghamton University
          finishing up my dual degree in Computer Science and Mathematical
          Sciences. I also just finished an internship with New York City's
          Cyber Command and am currently looking for a full time Software
          Engineer position for when I graduate. When I'm not attacking my semi
          colon key, I like to run, swim, lift, play chess, chill with friends
          and family, and watch youtube.
        </div>
      </div>
      {/* <div className="me-skills">
        <div className="me-skill">
          <div className="me-skill-title">Languages</div>
          <div className="me-skill-items">
            <img src={cIcon} alt="" className="me-skill-item"></img>
            <img src={jsIcon} alt="" className="me-skill-item"></img>
            <img src={cssIcon} alt="" className="me-skill-item"></img>
            <img src={htmlIcon} alt="" className="me-skill-item"></img>
          </div>
        </div>
        <div className="me-skill">
          <div className="me-skill-title">Frameworks</div>
          <div className="me-skill-items">
            <img src={reactIcon} alt="" className="me-skill-item"></img>
          </div>
        </div>
        <div className="me-skill">
          <div className="me-skill-title">Tools</div>
          <div className="me-skill-items">
            <img src={gitIcon} alt="" className="me-skill-item"></img>
            <img src={linuxIcon} alt="" className="me-skill-item"></img>
            <img src={bashIcon} alt="" className="me-skill-item"></img>
          </div>
        </div>
        <div className="me-skill"></div>
        <div className="me-skill"></div>
      </div> */}
    </section>
  );
};

export default Me;
